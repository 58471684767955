.App{
  background-color: #172430;
  color:white;
  height: 100vh;
  overflow: auto; /* fills screen */
  text-align: center;
  font-size: calc(10px + 1vmin);
}

.PolygonMap{
  max-width: 1000px;
  padding: 0px 15px;
  margin: auto; /* centers content relative to screen */
}

#polygon-map{
  width: 100%;
  height: 75vh;
}

.center-text{
  text-align: center;
  margin: 0;
}

.calculate-button{
  text-align: right;
  margin-top: 10px;
}

.results{
  max-width: 1000px;
  padding: 10px 20px;
  margin: auto; /* centers content relative to screen */
  text-align: left;
}

#results-map{
  width: 100%;
  height: 30vh;
}

.small-text{
  font-size: calc(5px + 1vmin);
  margin: 0;
}

.validating-text{
  font-size: calc(5px + 1vmin);
  margin: 0;
  text-align: left;
}

.transaction-details{
  width: 320px;
}
